<template>
  <div>
    <div id="J_prismPlayer"></div>
  </div>
</template>

<script>
//导出aliplayer
import "aliyun-aliplayer";
// 导出样式
import "aliyun-aliplayer/dist/skins/default/aliplayer-min.css";

export default {
  data() {
    return { url: "/static/logo.png" };
  },
  methods: {
    play() {
      var player = new Aliplayer(
        {
          id: "J_prismPlayer",
          source:
            "https://www.kepuxj.org.cn/8102e2f0f58c71edbb666723b78e0102/e9de32dadad14bf5bdd1d68fdf39d955-39571fee1858a741cb2859cec46e039c-ld.m3u8", //播放地址，可以是第三方点播地址，或阿里云点播服务中的播放地址。
        },
        function (player) {
          console.log("The player is created.");
        }
      );
    },
  },
  mounted() {
    this.play();
  },
};
</script>

<style></style>
